<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages" />
    <div class="flex flex-col p-6 flex-1">
      <p class="text-center" v-if="isLoading">Loading...</p>
      <div class="border-b pb-2" v-if="!isLoading">
        <div class="flex" :key="index" v-for="(filterData, index) in filters">
          <div
            class="w-1/3 cursor-pointer px-4 py-2"
            :key="filter.name"
            v-for="filter in filterData"
          >
            <label :for="filter.name">{{ filter.display_name }}</label>
            <component
              v-bind:is="filter.component"
              :placeholder="`Please Select ${filter.display_name}`"
              :key="
                filter.value_depend_field
                  ? filterValues[filter.value_depend_field]
                  : `${filter.name}_field`
              "
              :value="filterValues[filter.field]"
              :options="getFilterOptions(filter)"
              @selected="value => handleSelectFilterValue(filter, value)"
            >
            </component>
          </div>
        </div>
      </div>
      <div class="border-b pb-2 text-right mb-2" v-if="!isLoading">
        <button @click="handleResetFilter" style="background-color:#f700ff;" class="bg-purple-700 mt-2 text-white px-4 py-2">
          Reset Filter
        </button>
      </div>
      <DashboardWidgets
        :key="key"
        v-if="!isLoading && metricsData.length && resultsData.length"
        :metrics="metricsData"
        :results="resultsData"
        :group-by="getGroupBy"
      />
      <p class="text-center" v-if="!isLoading && !resultsData.length">
        No Data Found
      </p>
    </div>
  </div>
</template>

<script>
import {
  listSurveyAnalysisConfigurations,
  listSurveys,
  listSurveyAnalysisResults,
} from '../../graphql/queries';
import find from 'lodash-es/find';
import last from 'lodash-es/last';
import {filters} from './filters';
import chunk from 'lodash-es/chunk';
import filter from 'lodash-es/filter';
import lowerCase from 'lodash-es/lowerCase';
import capitalize from 'lodash-es/capitalize';
import API, {graphqlOperation} from '@aws-amplify/api';
import SelectDropdown from '@/components/SelectDropdown';
import DashboardWidgets from '@/components/DashboardWidgets';
import Topbar from '@/components/Topbar';
import {mapGetters} from 'vuex';

export default {
  components: {
    DashboardWidgets,
    SelectDropdown,
    Topbar,
  },
  computed: {
    pages() {
      return [
        {
          name: 'Dashboard',
          to: {name: 'home'},
          current: true,
        },
      ];
    },
    metricsData() {
      return filter(this.metrics, data => {
        return data.group_by === this.group_by;
      });
    },
    getGroupBy() {
      return capitalize(lowerCase(last(this.group_by.split(','))));
    },
    filters() {
      return chunk(filters, 3);
    },
    key() {
      const group = this.filterGroup[this.group_by];
      return `${this.group_by}_${group && group.value ? group.value : ''}`;
    },
    resultsData() {
      const group = this.filterGroup[this.group_by];
      if (group) {
        return filter(this.results, data => {
          return data[group.field] === group.value && data.group_by === this.group_by;
        });
      }
      return this.results;
    },
    ...mapGetters({
      group: 'app/getGroup',
    }),
  },
  data() {
    return {
      metrics: [],
      results: [],
      survey: null,
      isLoading: true,
      filterValues: {},
      filterGroup: {},
      group_by: 'district',
      userGroup: null,
    };
  },
  methods: {
    getFilterOptions(filter) {
      const mainData = find(filter.values, {key: 'data'});
      if (mainData && mainData.values) {
        return mainData.values;
      }
      if (filter.value_depend_field) {
        const dependField = this.filterValues[filter.value_depend_field];
        if (dependField) {
          const data = find(filter.values, {key: dependField});
          if (data && data.values) {
            return data.values;
          }
        } else {
          const values = filter.values;
          const deduced = values.map(value => value.values);
          const deducedIter = [];
          deduced.forEach(item => {
            item.map(value => {
              deducedIter.push(value);
            });
          });
          return deducedIter;
        }
      }
      return [];
    },
    async loadSurvey() {
      try {
        await API.graphql(graphqlOperation(listSurveys)).then(
          async ({
            data: {
              listSurveys: {items: items},
            },
          }) => {
            if (items.length > 0) {
              this.survey = items[0];
              await this.loadMetrics();
              await this.loadResults();
              this.isLoading = false;
            }
          },
        );
      } catch (error) {
        this.error = error.data?.message || error.statusText;
        this.isLoading = false;
      }
    },
    async loadMetrics() {
      try {
        await API.graphql(
          graphqlOperation(listSurveyAnalysisConfigurations, {survey_id: this.survey.id,limit:1000}),
        ).then(
          ({
            data: {
              listSurveyAnalysisConfigurations: {items},
            },
          }) => {
            this.metrics = items;
          },
        );
      } catch (error) {
        this.error = error.data?.message || error.statusText;
      }
    },
    async loadResults() {
      try {
        await API.graphql(
          graphqlOperation(listSurveyAnalysisResults, {survey_id: this.survey.id,limit:10000}),
        ).then(
          ({
            data: {
              listSurveyAnalysisResults: {items},
            },
          }) => {
            this.results = items;
          },
        );
      } catch (error) {
        this.error = error.data?.message || error.statusText;
      }
    },
    handleSelectFilterValue(filter, value) {
      this.group_by = filter.group_by;
      this.filterValues[filter.field] = value;
      this.filterGroup[filter.group_by] = {
        value: value,
        field: filter.field,
      };
    },
    handleResetFilter() {
      this.group_by = 'district';
      this.filterValues = {};
      this.filterGroup = {};
    },
  },
  async created() {
    this.isLoading = true;
    this.userGroup = await this.group;
    await this.loadSurvey();
  },
};
</script>
