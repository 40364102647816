<template>
  <div class="grid grid-cols-3 gap-4">
    <div v-for="(metric, key) in metricsData" :key="key" class="">
      <Default
        :key="groupBy"
        :metric="metric"
        :group-by="groupBy"
        :values="getValues(metric)"
        :global="metricsGlobalData(metric)"
      />
    </div>
  </div>
</template>
<script>
import Default from './Charts/index';
import filter from 'lodash-es/filter';

export default {
  props: {
    metrics: Array,
    results: Array,
    groupBy: String,
  },
  components: {
    Default,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    charts() {
      return [];
    },
    metricsData() {
      return filter(this.metrics, metric => {
          console.log("METRIC NAME:",  metric.metric_name);
        return !!filter(this.results, result => {
          if (result.metric_name.includes(metric.metric_name)) {
            console.log("RESULT METRIC NAME:", result.metric_name.includes(metric.metric_name), metric.metric_name, result.metric_name);
          }
          return (
            result.metric_name.includes(metric.metric_name) &&
            result.group_by === metric.group_by &&
            metric.measured_against_field !== '__global_count'
          );
        }).length;
      });
    },
  },
  methods: {
    getComponent(chart) {
      return chart.component ? chart.component : Default;
    },
    getValues(metric) {
      return filter(this.results, result => {
        return (
          result.metric_name.includes(metric.metric_name) && result.group_by === metric.group_by
        );
      });
    },
    metricsGlobalData(metric) {
      return filter(this.results, result => {
        return (
          result.group_by === metric.group_by && result.measured_against_field === '__global_count'
        );
      });
    },
  },
  async created() {
    this.isLoading = true;
  },
};
</script>
